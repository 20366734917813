<template>
  <page-layout class="ma-24">
    <!-- 搜索头 -->
    <search-header
      :schemaData="searchFileds"
      @getDataList="handleFormSearch"
      style="padding-left: 0px;"
    />
    <!-- 列表 -->
    <div class="mt-12 bg-white">
      <div
        class="px-24 py-16"
        style="border:1px solid #f3f4f6;border-bottom:1px solid #f3f4f6;"
      >
        <a-button type="primary" @click="addModal = true"
          >添加不开票车辆</a-button
        >
      </div>

      <p-table
        rowKey="id"
        dataKey="modal"
        :dataSource="dataSource"
        :columns="tableColumns"
        class="bg-white"
      ></p-table>
    </div>

    <!-- 新增不开票车辆弹窗-->
    <AddPlateNumber
      v-if="addModal"
      :visible="addModal"
      @getData="getConsultingList"
      @cancel="addModal = false"
    ></AddPlateNumber>
  </page-layout>
</template>
<script>
import { defineComponent, reactive, ref } from "vue";
import { useStore } from "vuex";
import { message } from "ant-design-vue";
import { getInitTable } from "@/hooks/form-hook";
import { Table } from "@wlhy-web-components/common";
import { searchFileds } from "./constants/index";
import { getColumns } from "./constants/table-config";
import useSearchForm from "@/hooks/UseSearchForm";
import {
  useEtcVehicleBlackListApi,
  useEtcVehicleBlackListDeleteApi
} from "@/apis/etc";
import $await from "@/utils/await";
import AddPlateNumber from "./components/AddPlateNumber/index";

export default defineComponent({
  name: "spending-bills",
  components: { AddPlateNumber },
  setup() {
    const store = useStore();

    const tableDataSource = reactive(getInitTable());
    const { filter, handleFormSearch } = useSearchForm(searchFileds);

    const etcVehicleBlackListApi = useEtcVehicleBlackListApi();
    const etcVehicleBlackListDeleteApi = useEtcVehicleBlackListDeleteApi();

    const addModal = ref(false);

    const getConsultingList = Table.useTableConfig({
      filter,
      dataSource: tableDataSource,
      async fetchData() {
        const data = {
          ...filter.value,
          size: tableDataSource.meta.pageSize,
          page: tableDataSource.meta.currentPageNo
        };
        const res = await etcVehicleBlackListApi(data);
        return { records: res.list, total: res.total };
      }
    });

    const tableColumns = reactive(getColumns());

    // 操作列渲染逻辑
    const invoiceProofNumber = tableColumns.find(v => v.dataIndex === "action");

    invoiceProofNumber.customRender = (text, records) => {
      return store.state.user.roles.includes("SUPER") ? (
        <div>
          <span
            style="color: #1684fb; cursor: pointer"
            onClick={() => handleDelete(records)}
          >
            移除
          </span>
        </div>
      ) : (
        <div></div>
      );
    };

    // 移除
    const handleDelete = async records => {
      const [, res] = await $await(
        etcVehicleBlackListDeleteApi({ id: records.id })
      );
      if (res) {
        message.success("移除成功！");
        getConsultingList();
      }
    };

    return {
      tableColumns,
      searchFileds,
      getConsultingList,
      handleFormSearch,
      dataSource: tableDataSource,
      addModal
    };
  }
});
</script>
