//table列配置
export const getColumns = () => {
  //  开票审核
  const columnsList = [
    { type: "checkbox", hidden: true },
    {
      width: 100,
      type: "seq",
      align: "left",
      title: "序号"
    },
    {
      title: "车牌号",
      dataIndex: "plate_number",
      minWidth: 160
    },
    {
      title: "添加时间",
      field: "create_time",
      minWidth: 140,
      cellRender: "p-date-time"
    },
    {
      title: "添加人",
      field: "creator",
      minWidth: 120
    },
    {
      title: "操作",
      dataIndex: "action",
      width: 140,
      align: "left",
      fixed: "right"
    }
  ];

  return columnsList;
};
