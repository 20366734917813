/**
 * 搜索头
 */
//  开票记录
export const searchFileds = [
  {
    field: "plate_number",
    label: "车牌号",
    type: "input",
    iw: 200
  }
];
