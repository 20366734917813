<template>
  <div>
    <xModal
      title="添加不可开票车辆"
      :visible="visible"
      :w="510"
      :footer="null"
      @handleCancel="handleCancel"
    >
      <template #content>
        <Form
          ref="formRef"
          :model="formState"
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          :rules="rules"
          class="form-box"
        >
          <FormItem label="车牌号" name="plate_number">
            <a-textarea
              v-model:value="formState.plate_number"
              :autoSize="{ minRows: 4, maxRows: 4 }"
              placeholder="请输入车牌号，多个请以回车换行。例：
浙A12345
浙A12345
浙A12345"
            ></a-textarea>
          </FormItem>

          <FormItem class="pt-24" :wrapper-col="{ span: 17, offset: 7 }">
            <a-button @click="handleCancel">取消</a-button>
            <a-button
              class="ml-16"
              type="primary"
              @click="handleSubmit"
              :loading="isLoading"
              >确定</a-button
            >
          </FormItem>
        </Form>
      </template>
    </xModal>
  </div>
</template>

<script>
import { ref } from "vue";
import { Form, message } from "ant-design-vue";
import { getInvoiceRules } from "./config";
import xModal from "@/components/xModal";
import $await from "@/utils/await";
import { useEtcVehicleBlackListCreateApi } from "@/apis/etc";

export default {
  props: {
    visible: { type: Boolean, default: false },
    type: { type: String, default: "" },
    dataInfo: { type: Object, default: () => {} }
  },
  components: {
    xModal,
    Form,
    FormItem: Form.Item
  },
  setup(props, ctx) {
    const etcVehicleBlackListCreateApi = useEtcVehicleBlackListCreateApi();

    const isLoading = ref(false);
    const formRef = ref();

    // 表单信息
    const formState = ref({
      plate_number: ""
    });

    const rules = getInvoiceRules(formState);

    // 提交表单
    const handleSubmit = () => {
      // 新增
      formRef.value.validate().then(async () => {
        const [, res] = await $await(
          etcVehicleBlackListCreateApi({
            plate_number: formState.value.plate_number.replace(/\n/g, ",")
          })
        );
        if (res) {
          message.success("添加成功！");
          isLoading.value = false;
          // 更新列表
          ctx.emit("getData");
          // 关闭弹窗
          handleCancel();
        } else {
          isLoading.value = false;
        }
      });
    };

    // 取消
    const handleCancel = () => {
      ctx.emit("cancel");
    };

    return {
      isLoading,
      formRef,
      formState,
      handleCancel,
      labelCol: { span: 6 },
      wrapperCol: { span: 18 },
      handleSubmit,
      rules
    };
  }
};
</script>

<style lang="less" scoped>
.input-grey {
  width: 320px;
  height: 32px;
  background: #f5f5f5;
  border-radius: 4px;
  padding-left: 8px;
  line-height: 32px;
}
</style>
<style lang="less"></style>
